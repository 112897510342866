import * as S from './QuestionHeader.styles';
import { ReactComponent as BookmarkIcon } from '@app/assets/icons/bookmark-icon.svg';

interface IQuestionHeaderProps {
  orderId: string;
  difficulty: string;
}

export const QuestionHeader: React.FC<IQuestionHeaderProps> = ({ orderId, difficulty }) => {
  return (
    <S.Wrapper>
      <div>
        <S.QuestionNum>{orderId}</S.QuestionNum>
      </div>
      <div className="text-base font-semibold">
        Look at the text below. In 40 seconds, you must read this text aloud as naturally and clearly as possible. You
        have 40 seconds to read aloud.
      </div>
      <div className="flex gap-4">
        <S.Tag difficulty={difficulty}>{difficulty}</S.Tag>
        <div className="cursor-pointer">
          <BookmarkIcon />
        </div>
      </div>
    </S.Wrapper>
  );
};
