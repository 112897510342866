import { useEffect, useRef, useState } from 'react';
import { STAGES } from '@app/constants/stages';
import * as S from './SpeakingRetellLecture.styles';
import { Recording } from '../Recording';
import { InfoModal } from '@app/components/common/Modal/Modal';
import { Completed } from '../Completed';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Answer } from '../../Answer';
import { WaitingTime } from '../WaitingTime';
import { AudioPlayer } from '@app/components/exam/AudioPlayer';
import { useLocation } from 'react-router-dom';
import useCountdownTimer from '@app/hooks/useCountdownTimer';

const gradingContent = 'Please write your name on essay cover sheet .';

interface SpeakingRetellLectureProps {
  prepTime: number;
  waitingTime: number;
  recordingTime: number;
  audioQuestion: string;
  showAnswer: boolean;
  questionNumber: string;
}

export const SpeakingRetellLecture: React.FC<SpeakingRetellLectureProps> = ({
  prepTime,
  waitingTime,
  recordingTime,
  audioQuestion,
  showAnswer,
  questionNumber,
}) => {
  const recordingRef = useRef<{ stopRecording: () => void }>(null);
  const location = useLocation();
  const [stage, setStage] = useState<string | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [audioURL, setAudioURL] = useState('');

  const { seconds, isRunning, start, pause, reset } = useCountdownTimer({
    initialSeconds: prepTime,
    onComplete: () => {
      setStage(STAGES.PLAYING);
      setIsPlaying(true);
    },
  });

  useEffect(() => {
    setIsPlaying(false);
    setStage(null);
    reset();

    InfoModal({
      title: 'Additional permissions',
      content: 'Click the OK button to enable record audio.',
      onOk: () => {
        start();
        setStage(STAGES.START);
      },
    });
  }, [questionNumber]);

  useEffect(() => {
    if (!showAnswer) return;
    if (stage == STAGES.RECORDING) {
      handleStopRecording();
    } else {
      setStage(STAGES.COMPLETE);
    }
  }, [showAnswer]);

  const handleEnded = () => {
    setIsPlaying(false);
    if (stage === STAGES.PLAYING) setStage(STAGES.WAITING);
  };

  const handleComplete = (audio: string) => {
    setStage(STAGES.COMPLETE);
    setAudioURL(audio);
  };

  const handleStopRecording = () => {
    recordingRef.current?.stopRecording();
  };

  const handleStart = () => {
    setStage(STAGES.RECORDING);
  };

  return (
    <>
      <S.Wrapper>
        {stage === STAGES.START && (
          <p>
            Beginning in <S.TextRed>{seconds}</S.TextRed> seconds
          </p>
        )}
        {(stage === STAGES.PLAYING || stage === STAGES.RECORDING) && <p>Status: playing...</p>}
        {stage === STAGES.COMPLETE && <p>Status: Completed</p>}
        <AudioPlayer audioQuestion={audioQuestion} onEnded={handleEnded} startPlaying={isPlaying} />
      </S.Wrapper>

      {stage === STAGES.WAITING && (
        <div className="mt-5">
          <WaitingTime onStart={handleStart} waitingTime={waitingTime} />
        </div>
      )}

      {stage === STAGES.RECORDING && (
        <div className="mt-5">
          <Recording ref={recordingRef} recordingTime={recordingTime} onComplete={handleComplete} />
        </div>
      )}

      {stage === STAGES.COMPLETE && (
        <div className="mt-5">
          <Completed />
        </div>
      )}

      <div className="mt-5">
        {showAnswer && <Answer audioURL={audioURL} showNote={true} gradingContent={gradingContent} />}
      </div>
    </>
  );
};
