import { httpApi } from '@app/api/http.api';
import { number } from 'echarts';
// export interface IQuestion {
//     description: string;
//     imageUrl: string;
//     name: string;
//     numberTested: number;
//     remainAttemps: number;
//     skillSet: string;
//     total: number;
//     type: string;
// }

export interface IQuestionByType {
  type: string;
  total: number;
}

export interface IQuestion {
  type: string;
  total: number;
}

export interface IExam {
  id: string;
  audioLink: string;
  text: string;
  orderId: string;
  difficulty: string;
  description: string;
  choices: string;
  oldChoices: string;
  answer: string;
  imageUrl: string;
}

export interface IExamList {
  data: IExam[];
  meta: {
    itemCount: number;
    page: number;
  };
}

export const getQuestionsByType = (type: string, categories: string[]): Promise<IQuestionByType[]> => {
  const queryString = `questionType=${encodeURIComponent(type)}&${categories
    .map((category) => `questionSubType=${encodeURIComponent(category)}`)
    .join('&')}`;

  return httpApi.get<IQuestionByType[]>(`/questions/grouped-by-type?${queryString}`).then(({ data }) => data);

  // return new Promise((res) => {
  //   setTimeout(() => {
  //     res([
  //       {
  //         type: 'SPEAKING_READ_ALOUD',
  //         total: 696,
  //       },
  //       {
  //         type: 'SPEAKING_REPEAT_SENTENCE',
  //         total: 787,
  //       },
  //       {
  //         type: 'SPEAKING_DESCRIBE_IMAGE',
  //         total: 787,
  //       },
  //       {
  //         type: 'SPEAKING_RETELL_LECTURE',
  //         total: 787,
  //       },
  //       {
  //         type: 'SPEAKING_ANSWER_SHORT_QUESTION',
  //         total: 787,
  //       },
  //       {
  //         type: 'WRITING_SUMMARIZE_WRITTEN_TEXT',
  //         total: 787,
  //       },
  //       {
  //         type: 'READING_MCQ_R_SINGLE_ANSWER',
  //         total: 787,
  //       },
  //       {
  //         type: 'READING_MCQ_R_MULTIPLE_ANSWER',
  //         total: 787,
  //       },
  //       {
  //         type: 'READING_FIB_R',
  //         total: 787,
  //       },
  //       {
  //         type: 'READING_FIB_R_W',
  //         total: 787,
  //       },
  //       {
  //         type: 'READING_RE_ORDER_PARAGRAPH',
  //         total: 787,
  //       },
  //       {
  //         type: 'LISTENING_DICTATION',
  //         total: 787,
  //       },
  //       {
  //         type: 'LISTENING_HIGHLIGHT_INCORRECT_WORD',
  //         total: 787,
  //       },
  //       {
  //         type: 'LISTENING_MCQ_L_SINGLE_ANSWER',
  //         total: 787,
  //       },
  //       {
  //         type: 'LISTENING_FIB_L',
  //         total: 787,
  //       },
  //       {
  //         type: 'LISTENING_SUMMARIZE_SPOKEN_TEXT',
  //         total: 787,
  //       },
  //       {
  //         type: 'LISTENING_HIGHLIGHT_CORRECT_SUMMARY',
  //         total: 787,
  //       },
  //       {
  //         type: 'LISTENING_SELECT_MISSING_WORD',
  //         total: 787,
  //       },
  //       {
  //         type: 'LISTENING_MCQ_L_MULTIPLE_ANSWER',
  //         total: 787,
  //       },
  //     ]);
  //   }, 0);
  // });
};

export const getExams = (page: number, take: number, questionSubType: string): Promise<IExamList> => {
  const queryString = `order=ASC&page=${page}&take=${take}&questionSubType=${encodeURIComponent(questionSubType)}`;

  return httpApi.get<IExamList>(`/questions?${queryString}`).then(({ data }) => data);
};

export const getDetailExam = (id: string): Promise<IExam> => {
  return httpApi.get<IExam>(`/questions/${id}`).then(({ data }) => data);
};
