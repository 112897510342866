import React, { useState } from 'react';
import { GoogleLoginResponse, GoogleLoginResponseOffline, useGoogleLogin } from 'react-google-login';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { ReactComponent as GoogleIcon } from '@app/assets/icons/google.svg';
import { doLoginGoogle } from '@app/store/slices/authSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { useNavigate } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';

const clientId = '272254487159-2an73d41j5n9vagup98a47uv46t97fkv.apps.googleusercontent.com';

const LoginWithGoogle = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const onSuccess = (res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if ('accessToken' in res) {
      loginGoogle(res.accessToken);
    }
  };

  const onFailure = (error: any) => {
    console.error('Login Failed:', error);
  };

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    isSignedIn: false,
  });

  const loginGoogle = (token: string) => {
    setLoading(true);
    const _data = {
      accessToken: token,
    };
    dispatch(doLoginGoogle(_data))
      .unwrap()
      .then(() => navigate('/'))
      .catch((err) => {
        console.log(err);
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  return (
    <Auth.SocialButton type="default" htmlType="button" onClick={signIn} loading={isLoading}>
      <Auth.SocialIconWrapper>
        <GoogleIcon />
      </Auth.SocialIconWrapper>
      Đăng nhập với Google
    </Auth.SocialButton>
  );
};

export default LoginWithGoogle;
