import React, { useEffect, useState } from 'react';
import * as S from './ReadingFIBR.styles';
import { Button } from '@app/components/common/buttons/Button/Button';

interface Props {
  showAnswer: boolean;
  text: string;
  initialChoicesString: string;
  correctAnswersString: string;
}

export const ReadingFIBR: React.FC<Props> = ({ showAnswer, text, initialChoicesString, correctAnswersString }) => {
  const numberOfBlanks = (text.match(/@Blank@/g) || []).length;
  const [filledBlanks, setFilledBlanks] = useState<string[]>(new Array(numberOfBlanks).fill(''));

  const [initialChoices, setInitialChoices] = useState<{ label: string; text: string }[]>([]);
  const [choices, setChoices] = useState<{ label: string; text: string }[]>([]);
  const [correctAnswers, setCorrectAnswers] = useState<string[]>([]);

  useEffect(() => {
    setFilledBlanks(new Array(numberOfBlanks).fill(''));
    if (initialChoicesString) {
      const parsedChoices = JSON.parse(initialChoicesString);
      setChoices(parsedChoices);
      setInitialChoices(parsedChoices);
    }
  }, [initialChoicesString]);

  useEffect(() => {
    if (correctAnswersString) {
      const resultArray = correctAnswersString.split(',').map((item) => item.trim());
      setCorrectAnswers(resultArray);
    }
  }, [correctAnswersString]);

  const onDragStart = (event: React.DragEvent<HTMLDivElement>, index: number) => {
    event.dataTransfer.setData('text/plain', index.toString());
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>, blankIndex: number) => {
    const choiceIndex = parseInt(event.dataTransfer.getData('text/plain'));
    const newFilledBlanks = [...filledBlanks];
    newFilledBlanks[blankIndex] = choices[choiceIndex].text;
    setFilledBlanks(newFilledBlanks);

    setChoices(choices.filter((_, index) => index !== choiceIndex));
  };

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const textWithBlanks = text.split(/@Blank@/).map((part, index) => {
    const filledBlank = filledBlanks[index] || '';
    const isBlank = index < filledBlanks.length;

    const foundChoice = initialChoices.find((choice) => filledBlank === choice.text);
    const filledLabel = foundChoice ? foundChoice.label : null;
    const isCorrect = filledLabel && correctAnswers[index] === filledLabel;

    return (
      <React.Fragment key={`part-${index}`}>
        {part}
        {isBlank && (
          <S.SmoothDND
            onDrop={(event: React.DragEvent<HTMLDivElement>) => isBlank && onDrop(event, index)}
            onDragOver={onDragOver}
            style={{
              borderColor: showAnswer ? (isCorrect ? 'green' : 'red') : '#000',
            }}
          >
            {filledBlank}
          </S.SmoothDND>
        )}
        {showAnswer && !isCorrect && correctAnswers[index] && (
          <S.CorrectAnswer>
            ({initialChoices.find((choice) => choice.label == correctAnswers[index])?.text})
          </S.CorrectAnswer>
        )}
      </React.Fragment>
    );
  });

  return (
    <>
      <S.Wrapper>
        <S.ContenQuestion>{textWithBlanks}</S.ContenQuestion>

        <S.ListChoices>
          {choices.map((choice, index) => (
            <S.Choices key={choice.label} draggable onDragStart={(event) => onDragStart(event, index)}>
              {choice.text}
            </S.Choices>
          ))}
        </S.ListChoices>
      </S.Wrapper>
    </>
  );
};
