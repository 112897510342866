'use client';

import React, { useState } from 'react';
import MockTest from './mock-test';

const itemsPerPage = 9;

interface MockTest {
  id: number;
  title: string;
  status: 'COMPLETED' | 'NOT YET TAKEN' | 'IN PROGRESS';
  year: number;
  duration: string;
}

interface MockTestListProps {
  mockTests: MockTest[];
}

const MockTestList: React.FC<MockTestListProps> = ({ mockTests }) => {
  const [currentPage, setCurrentPage] = useState(1);
  console.log('mockTests', setCurrentPage);

  const indexOfLastTest = currentPage * itemsPerPage;
  const indexOfFirstTest = indexOfLastTest - itemsPerPage;
  const currentTests = mockTests.slice(indexOfFirstTest, indexOfLastTest);

  const handleTestClick = (id: number) => {
    console.log(`Test with id ${id} clicked`);
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="w-full max-w-4xl" style={{ minHeight: '200px' }}>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
          {currentTests.map((test, index) => (
            <MockTest
              key={index}
              title={test.title}
              status={test.status}
              year={test.year}
              duration={test.duration}
              onClick={() => handleTestClick(test.id)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MockTestList;
