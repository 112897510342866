import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Duration = styled.div`
  font-size: 0.875rem;
  white-space: nowrap;
`;

export const StyledSelect = styled.select`
  font-size: 0.875rem;
  transition: all 0.15s ease-in-out;
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);

  &:focus {
    outline: none;
  }
`;

export const Download = styled(Link)`
  padding: 0.1rem;
  text-decoration: none;
  border-radius: 4px;

  &:hover {
    background-color: var(--primary-color);
  }
`;
