import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import * as S from './ReadingReOrderParagraph.styles';
import { Modal } from '@app/components/common/Modal/Modal';

interface Props {
  showAnswer: boolean;
  initialChoicesString: string;
  correctAnswersString: string;
}

interface Item {
  label: string;
  text: string;
}

type Column = {
  name: string;
  items: Item[];
};

type Columns = {
  [key: string]: Column;
};

// const choices: Item[] = [
//   {
//     label: 'A',
//     text: 'Now music could be communicated efficiently, and succeeding generations would know something about the music of their ancestors.',
//   },
//   {
//     label: 'B',
//     text: 'It was the development of a standard musical notation in the 11th century that allowed music to be documented in a physical form.',
//   },
//   {
//     label: 'C',
//     text: 'In particular a written language can convey a lot of information about past events, places, people and things.',
//   },
//   { label: 'D', text: 'But it is difficult to describe music in words, and even more difficult to specify a tune.' },
//   { label: 'E', text: 'Over the years many human endeavors have had the benefit of language.' },
// ];

// const answer = 'E, C, D, B, A';

export const ReadingReOrderParagraph: React.FC<Props> = ({
  showAnswer,
  initialChoicesString,
  correctAnswersString,
}) => {
  const [choices, setChoices] = useState<{ label: string; text: string }[]>([]);
  const [modal, setModal] = useState(false);
  const [columns, setColumns] = useState<Columns>({
    source: { name: 'Source', items: [] },
    target: { name: 'Target', items: [] },
  });

  useEffect(() => {
    if (initialChoicesString) {
      const parsedChoices = JSON.parse(initialChoicesString);

      setColumns((prevColumns) => ({
        ...prevColumns,
        source: { ...prevColumns.source, items: parsedChoices },
        target: { ...prevColumns.target, items: [] },
      }));
      setChoices(parsedChoices);
    }
  }, [initialChoicesString]);

  useEffect(() => {
    if (showAnswer) setModal(true);
  }, [showAnswer]);

  const renderAnswerDisplay = () => {
    const choicesMap = choices.reduce((acc, choice) => {
      acc[choice.label] = choice;
      return acc;
    }, {} as Record<string, typeof choices[0]>);

    const answerLabels = correctAnswersString.split(',').map((label) => label.trim());

    const displayedChoices = answerLabels.map((label) => {
      const choice = choicesMap[label];
      return choice ? (
        <p className="mb-2" key={choice.label}>
          {choice.label}. {choice.text}
        </p>
      ) : null;
    });

    return <>{displayedChoices}</>;
  };

  const onDragEnd = (
    result: DropResult,
    columns: Columns,
    setColumns: React.Dispatch<React.SetStateAction<Columns>>,
  ) => {
    if (!result.destination) return;
    const { source, destination } = result;

    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const [movedItem] = sourceItems.splice(source.index, 1);

    // Kiểm tra nếu kéo thả qua cột khác
    if (source.droppableId !== destination.droppableId) {
      const destItems = [...destColumn.items];
      destItems.splice(destination.index, 0, movedItem);

      setColumns((prev) => ({
        ...prev,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      }));
    } else {
      // Cùng cột
      sourceItems.splice(destination.index, 0, movedItem);

      setColumns((prev) => ({
        ...prev,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
      }));
    }
  };

  return (
    <>
      <S.Wrapper>
        <DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns)}>
          {Object.entries(columns).map(([columnId, column], index) => {
            return (
              <div className="flex-1" key={columnId}>
                <S.Title level={5}>{column.name}</S.Title>

                <S.Box>
                  <Droppable droppableId={columnId} key={columnId}>
                    {(provided, snapshot) => {
                      return (
                        <S.DroppableContainer
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          isDraggingOver={snapshot.isDraggingOver}
                        >
                          {column.items.map((item, index) => {
                            return (
                              <Draggable key={item.label} draggableId={item.label} index={index}>
                                {(provided, snapshot) => {
                                  return (
                                    <S.DraggableItem
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      isDragging={snapshot.isDragging}
                                      style={provided.draggableProps.style}
                                    >
                                      {item.label}. {item.text}
                                    </S.DraggableItem>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </S.DroppableContainer>
                      );
                    }}
                  </Droppable>
                </S.Box>
              </div>
            );
          })}
        </DragDropContext>
      </S.Wrapper>

      <Modal title={`Answer`} visible={modal} footer={[]} onCancel={() => setModal(false)}>
        {showAnswer && renderAnswerDisplay()}
      </Modal>
    </>
  );
};
