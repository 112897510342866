import { Typography } from 'antd';
import styled from 'styled-components';

export const CardAnswer = styled.div`
  border: 1px solid #000;
  border-radius: 4px;
  padding: 22px 24px;
  margin-bottom: 5rem;
`;

export const CardAnswerHead = styled.div``;

export const CardAnswerBody = styled.div``;

export const Title = styled(Typography.Title)`
  font-weight: 700;
  line-height: 1.1;
  font-size: 1.625rem;
  margin: 0 0 2rem;
  color: #333 !important;
`;
