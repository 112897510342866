import { useEffect, useState } from 'react';
import * as S from './WritingSummarizeWrittenText.styles';
import { TextArea } from '@app/components/common/inputs/Input/Input';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Answer } from '../../Answer';
import { useDispatch } from 'react-redux';

interface Props {
  showAnswer: boolean;
  text: string;
  questionNumber: string;
}

export const WritingSummarizeWrittenText: React.FC<Props> = ({ showAnswer, text, questionNumber }) => {
  const [answerContent, setAnswerContent] = useState<string>('');
  const wordCount = answerContent?.trim().split(/\s+/).filter(Boolean).length || 0;

  useEffect(() => {
    setAnswerContent('');
  }, [questionNumber]);

  return (
    <>
      <S.ContentQuestion>{text}</S.ContentQuestion>
      <TextArea
        rows={10}
        placeholder="Please write your answer here"
        value={answerContent}
        onChange={(e) => setAnswerContent(e.target.value)}
      />

      <div>
        Word count: <span style={{ color: wordCount > 75 ? 'red' : 'black' }}>{wordCount}</span>
        /75
      </div>

      <div className="mt-5">{showAnswer && <Answer />}</div>
    </>
  );
};
