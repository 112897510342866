import { useNavigate } from 'react-router-dom';
import { Table } from 'components/common/Table/Table';
import * as S from './ResultPage.styles';
import { useState } from 'react';
import { Button } from '@app/components/common/buttons/Button/Button';
import { EyeOutlined } from '@ant-design/icons';

export const ResultPage: React.FC = () => {
  const navigate = useNavigate();
  const [columns, setColumns] = useState([
    { title: 'Code', dataIndex: 'code' },
    { title: 'Name', dataIndex: 'name' },
    { title: 'Email', dataIndex: 'email' },
    { title: 'Status', dataIndex: 'status' },
    { title: 'Test Time', dataIndex: 'time' },
    { title: 'Type', dataIndex: 'type' },
    { title: 'Point', dataIndex: 'point' },
    { title: 'Memo', dataIndex: 'memo' },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: '15%',
      render: (text: string, record: { name: string; key: number }) => {
        return (
          <Button
            type="primary"
            size="small"
            icon={<EyeOutlined />}
            onClick={() => handleViewDetails(record.key)}
          ></Button>
        );
      },
    },
  ]);
  const [data, setData] = useState([
    {
      key: 1,
      code: 1,
      name: 'John Brown',
      email: 'john@gmail.com',
      status: 'Marked',
      time: '2021-10-10',
      type: 'Practice Test',
      point: '6.5',
      memo: 'This is memo',
    },
    {
      key: 2,
      code: 2,
      name: 'John Brown',
      email: 'john@gmail.com',
      status: 'Marked',
      time: '2021-10-10',
      type: 'Practice Test',
      point: '6.5',
      memo: 'This is memo',
    },
  ]);
  const [loading, setLoading] = useState(false);

  const handleViewDetails = (id: number) => {
    navigate(`/result/${id}`);
  };

  return (
    <div className="flex flex-col rounded-lg bg-white p-8">
      <S.Title level={3}>Result</S.Title>
      <div>
        <p className="mt-2 text-sm">This is the result page. Here you can see the result of the test.</p>

        <Table columns={columns} dataSource={data} loading={loading} scroll={{ x: 800 }} bordered />
      </div>
    </div>
  );
};
