import { useEffect, useState } from 'react';
import * as S from './Exam.styles';
import { QuestionHeader } from './QuestionHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { QUESTIONS } from '@app/constants/questions';
import SpeakingReadAloud from './Speaking/Question/SpeakingReadAloud';
import { SpeakingRepeatSentence } from './Speaking/Question/SpeakingRepeatSentence';
import { SpeakingDescribeImage } from './Speaking/Question/SpeakingDescribeImage';
import { SpeakingRetellLecture } from './Speaking/Question/SpeakingRetellLecture';
import { DrawerListQuestion } from './DrawerListQuestion';
import { LeftArrowIcon } from '../common/icons/LeftArrowIcon';
import { RightArrowIcon } from '../common/icons/RightArrowIcon';
import { WritingSummarizeWrittenText } from './Writing/Question/WritingSummarizeWrittenText';
import { ReadingMCQRSingleAnswer } from './Reading/Question/ReadingMCQRSingleAnswer';
import { ReadingMCQRMultipleAnswer } from './Reading/Question/ReadingMCQRMultipleAnswer';
import { ReadingFIBR } from './Reading/Question/ReadingFIBR';
import { ReadingFIBRW } from './Reading/Question/ReadingFIBRW';
import { ReadingReOrderParagraph } from './Reading/Question/ReadingReOrderParagraph';
import { ListeningDictation } from './Listening/Question/ListeningDictation';
import { ListeningMCQRSingleAnswer } from './Listening/Question/ListeningMCQRSingleAnswer';
import { ListeningFIBL } from './Listening/Question/ListeningFIBL';
import { ListeningMCQLMultipleAnswer } from './Listening/Question/ListeningMCQLMultipleAnswer';
import { ListeningSummarizeSpokenText } from './Listening/Question/ListeningSummarizeSpokenText';
import { ListeningHighlightIncorectWord } from './Listening/Question/ListeningHighlightIncorectWord';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/store/store';
import { fetchExamDetail, fetchExamList } from '@app/store/slices/examSlice';

export const Exam: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { listExam, currentQuestion, page } = useSelector((state: RootState) => state.exam);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');
  const questionId = queryParams.get('id');
  const pageFromUrl = queryParams.get('page');

  const { SPEAKING, WRITING, READING, LISTENING } = QUESTIONS;
  const [open, setOpen] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const [again, setAgain] = useState(false);

  useEffect(() => {
    if (!type) return;

    if (pageFromUrl) {
      const _page = parseInt(pageFromUrl);
      if (_page) {
        dispatch(fetchExamList({ page: _page, type }));
      }
    } else {
      dispatch(fetchExamList({ page, type }));
    }
  }, [page, dispatch]);

  useEffect(() => {
    retry();

    if (questionId) {
      dispatch(fetchExamDetail(questionId));
    } else if (listExam.length > 0) {
      dispatch(fetchExamDetail(listExam[0].id));
    }
  }, [questionId, listExam, navigate, dispatch]);

  const navigateToQuestion = (direction: 'next' | 'previous') => {
    const currentIndex = listExam.findIndex((item) => item.id === currentQuestion?.id);
    const newIndex = direction === 'next' ? currentIndex + 1 : currentIndex - 1;

    if (newIndex >= 0 && newIndex < listExam.length) {
      navigate(`/exam?type=${type}&id=${listExam[newIndex].id}&page=${page}`);
    }
  };

  const handleNext = () => navigateToQuestion('next');

  const handlePrevious = () => navigateToQuestion('previous');

  const retry = () => {
    setShowAnswer(false);
    setAgain(false);
  };

  const renderQuestion = () => {
    if (!currentQuestion) return;
    switch (type) {
      case SPEAKING.READ_ALOUD:
        return (
          <SpeakingReadAloud
            waitingTime={40}
            recordingTime={40}
            showAnswer={showAnswer}
            text={currentQuestion.text}
            questionNumber={currentQuestion.id}
          />
        );
      case SPEAKING.REPEAT_SENTENCE:
        return (
          <SpeakingRepeatSentence
            prepTime={5}
            recordingTime={10}
            audioQuestion={currentQuestion?.audioLink}
            showAnswer={showAnswer}
            questionNumber={currentQuestion.id}
          />
        );
      case SPEAKING.DESCRIBE_IMAGE:
        return (
          <SpeakingDescribeImage
            prepTime={25}
            recordingTime={40}
            showAnswer={showAnswer}
            imageUrl={currentQuestion.imageUrl}
            questionNumber={currentQuestion.id}
          />
        );
      case SPEAKING.RETELL_LECTURE:
        return (
          <SpeakingRetellLecture
            prepTime={5}
            recordingTime={40}
            waitingTime={10}
            audioQuestion={currentQuestion?.audioLink}
            showAnswer={showAnswer}
            questionNumber={currentQuestion.id}
          />
        );
      case SPEAKING.ANSWER_SHORT_QUESTION:
        return (
          <SpeakingRetellLecture
            prepTime={3}
            recordingTime={10}
            waitingTime={3}
            audioQuestion={currentQuestion?.audioLink}
            showAnswer={showAnswer}
            questionNumber={currentQuestion.id}
          />
        );
      case WRITING.SUMMARIZE_WRITTEN_TEXT:
      case WRITING.WRITING_ESSAY:
        return (
          <WritingSummarizeWrittenText
            showAnswer={showAnswer}
            text={currentQuestion.text}
            questionNumber={currentQuestion.id}
          />
        );
      case READING.MCQ_R_SINGLE_ANSWER:
        return (
          <ReadingMCQRSingleAnswer
            showAnswer={showAnswer}
            text={currentQuestion.text}
            description={currentQuestion.description}
            initialChoicesString={currentQuestion.oldChoices}
            answer={currentQuestion.answer}
          />
        );
      case READING.MCQ_R_MULTIPLE_ANSWER:
        return (
          <ReadingMCQRMultipleAnswer
            showAnswer={showAnswer}
            text={currentQuestion.text}
            description={currentQuestion.description}
            initialChoicesString={currentQuestion.oldChoices}
            correctAnswersString={currentQuestion.answer}
          />
        );
      case READING.FIB_R:
        return (
          <ReadingFIBR
            showAnswer={showAnswer}
            text={currentQuestion.text}
            initialChoicesString={currentQuestion.oldChoices}
            correctAnswersString={currentQuestion.answer}
          />
        );
      case READING.FIB_R_W:
        return (
          <ReadingFIBRW
            showAnswer={showAnswer}
            text={currentQuestion.text}
            initialChoicesString={currentQuestion.oldChoices}
            correctAnswersString={currentQuestion.answer}
          />
        );
      case READING.RE_ORDER_PARAGRAPH:
        return (
          <ReadingReOrderParagraph
            showAnswer={showAnswer}
            initialChoicesString={currentQuestion.oldChoices}
            correctAnswersString={currentQuestion.answer}
          />
        );
      case LISTENING.DICTATION:
        return (
          <ListeningDictation
            prepTime={5}
            audioQuestion={currentQuestion.audioLink}
            audioAnswer="https://storage.googleapis.com/pte-magic-question-2018/question_LISTENING_DICTATION1594804300441_stdnew.mp3"
            showAnswer={showAnswer}
            questionNumber={currentQuestion.id}
          />
        );
      case LISTENING.MCQ_L_SINGLE_ANSWER:
      case LISTENING.HIGHLIGHT_CORRECT_SUMMARY:
      case LISTENING.SELECT_MISSING_WORD:
        return (
          <ListeningMCQRSingleAnswer
            prepTime={5}
            audioQuestion={currentQuestion.audioLink}
            showAnswer={showAnswer}
            questionNumber={currentQuestion.id}
            initialChoicesString={currentQuestion.oldChoices}
            correctAnswersString={currentQuestion.answer}
          />
        );
      case LISTENING.FIB_L:
        return (
          <ListeningFIBL
            prepTime={5}
            audioQuestion={currentQuestion.audioLink}
            showAnswer={showAnswer}
            description={currentQuestion.description}
            correctAnswersString={currentQuestion.answer}
          />
        );
      case LISTENING.MCQ_L_MULTIPLE_ANSWER:
        return (
          <ListeningMCQLMultipleAnswer
            prepTime={5}
            audioQuestion={currentQuestion.audioLink}
            showAnswer={showAnswer}
            initialChoicesString={currentQuestion.oldChoices}
            correctAnswersString={currentQuestion.answer}
            questionNumber={currentQuestion.id}
          />
        );

      case LISTENING.SUMMARIZE_SPOKEN_TEXT:
        return (
          <ListeningSummarizeSpokenText
            prepTime={5}
            audioQuestion={currentQuestion.audioLink}
            showAnswer={showAnswer}
            questionNumber={currentQuestion.id}
          />
        );
      case LISTENING.HIGHLIGHT_INCORRECT_WORD:
        return (
          <ListeningHighlightIncorectWord
            prepTime={5}
            audioQuestion={currentQuestion.audioLink}
            showAnswer={showAnswer}
            description={currentQuestion.description}
            correctAnswersString={currentQuestion.answer}
            questionNumber={currentQuestion.id}
          />
        );
      default:
        return null;
    }
  };

  const handleTryAgain = () => {
    window.location.reload();
  };

  const handleAnswer = () => {
    setShowAnswer(true);
    setAgain(true);
  };

  if (!currentQuestion) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <S.Wrapper>
        {/* Question Header */}
        <QuestionHeader orderId={currentQuestion?.orderId} difficulty={currentQuestion?.difficulty} />
        {/* End Question Header */}

        {/* Question */}
        <div className="mt-5">{renderQuestion()}</div>
        {/* End Question */}
      </S.Wrapper>

      {/* Footer */}
      <S.Footer>
        <Button onClick={handlePrevious}>Previous</Button>
        {!again ? (
          <Button type="default" onClick={handleAnswer}>
            Submit
          </Button>
        ) : (
          <Button type="default" onClick={handleTryAgain}>
            Try again
          </Button>
        )}
        <Button onClick={handleNext}>Next</Button>
      </S.Footer>
      {/* End Footer */}

      <S.ToggleBtn>
        <Button
          type="primary"
          shape="circle"
          icon={open ? <RightArrowIcon /> : <LeftArrowIcon />}
          onClick={() => setOpen(true)}
          className="flex items-center justify-center"
        ></Button>
      </S.ToggleBtn>

      <DrawerListQuestion
        open={open}
        toggle={() => {
          setOpen(false);
        }}
        type={type || ''}
      />
    </>
  );
};
