import { useEffect, useRef, useState } from 'react';
import { Button } from '@app/components/common/buttons/Button/Button';
import { STAGES } from '@app/constants/stages';
import { Recording } from '../Recording';
import { Completed } from '../Completed';
import { Answer } from '../../Answer';
import { WaitingTime } from '../WaitingTime';

interface Props {
  waitingTime: number;
  recordingTime: number;
  showAnswer: boolean;
  text: string;
  questionNumber: string;
}

const SpeakingReadAloud: React.FC<Props> = ({ waitingTime, recordingTime, showAnswer, text, questionNumber }) => {
  const recordingRef = useRef<{ stopRecording: () => void }>(null);
  const [stage, setStage] = useState(STAGES.START);
  const [audioURL, setAudioURL] = useState('');

  useEffect(() => {
    handleStopRecording();
    setStage(STAGES.START);
  }, [questionNumber]);

  useEffect(() => {
    if (!showAnswer) return;
    if (stage == STAGES.START) {
      setStage(STAGES.COMPLETE);
    } else {
      handleStopRecording();
    }
  }, [showAnswer]);

  const handleStart = () => {
    setStage(STAGES.RECORDING);
  };

  const handleComplete = (audio: string) => {
    setStage(STAGES.COMPLETE);
    setAudioURL(audio);
  };

  const handleStopRecording = () => {
    if (recordingRef.current) {
      recordingRef.current.stopRecording();
    }
  };

  return (
    <>
      {stage === STAGES.START && <WaitingTime onStart={handleStart} waitingTime={waitingTime} />}
      {stage === STAGES.RECORDING && (
        <Recording ref={recordingRef} recordingTime={recordingTime} onComplete={handleComplete} />
      )}

      {stage === STAGES.COMPLETE && <Completed />}

      <div className="mt-5">{text}</div>

      <div className="mt-5">{showAnswer && <Answer audioURL={audioURL} showNote={true} gradingContent={text} />}</div>
    </>
  );
};

export default SpeakingReadAloud;
