import { IDataType } from '@app/interfaces/interfaces';
import { Descriptions, Table, TableProps } from 'antd';

interface TableCollapseProps<RecordType> {
  columns: TableProps<RecordType>['columns'];
  dataSource: RecordType[];
}

export const TableCollapse: React.FC<TableCollapseProps<IDataType>> = ({ columns, dataSource, ...rest }) => {
  return (
    <Table
      expandable={{
        expandedRowRender: (record) => {
          return (
            <Descriptions bordered column={1} title="Question Details" size="small">
              <Descriptions.Item label="Question">{record.question}</Descriptions.Item>
              <Descriptions.Item label="Answer">{record.answer}</Descriptions.Item>
              <Descriptions.Item label="Marks">
                <span className="text-green-500 font-semibold">{record.marks}</span>
              </Descriptions.Item>
            </Descriptions>
          );
        },
        rowExpandable: (record) => record.name !== 'Not Expandable',
        expandRowByClick: true,
        defaultExpandAllRows: false,
      }}
      columns={columns}
      dataSource={dataSource}
      {...rest}
    />
  );
};
