import styled from 'styled-components';

export const WrapperAudio = styled.div`
  width: 100%;
  max-width: 28rem;
  border-radius: 0.5rem;
  padding: 1rem;
  border-style: dashed;
  border-width: 2px;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Wrapper = styled.div`
  width: 100%;
  border: 1px solid #000;
  border-radius: 10px;
  margin: 24px 0;
`;

export const ContenQuestion = styled.div`
  padding: 20px;
  line-height: 40px;
`;

export const CorrectAnswer = styled.span`
  color: green;
  margin-left: 5px;
  font-weight: 600;
`;

export const InputStyle = styled.input`
  margin: 5px;
  border: 1px solid #ccc;
  height: 30px;
  border-radius: 5px;
  background: #fff;
`;

export const TextRed = styled.span`
  color: red;
`;
