import React from 'react';
export interface MockTestProps {
  title: string;
  status: string;
  year: number;
  duration: string;
  onClick: () => void;
}
const MockTest: React.FC<MockTestProps> = ({ title, status, year, duration, onClick }) => {
  const statusStyles: { [key: string]: string } = {
    'IN PROGRESS': 'bg-blue-500',
    'NOT YET TAKEN': 'bg-gray-400',
    COMPLETED: 'bg-green-500',
  };

  return (
    <button
      className="mb-4 flex cursor-pointer flex-col rounded-sm border border-solid border-[#333] p-4 shadow-md"
      onClick={onClick}
      onKeyDown={(event) => event.key === 'Enter' && onClick()}
    >
      <span
        className={`
        mb-2 w-fit rounded-3xl px-2 py-1 text-xs font-semibold text-white
         ${statusStyles[status]}`}
      >
        {status}
      </span>
      <h3 className="text-lg font-semibold">{title}</h3>
      <p className="my-2 text-sm font-medium">This AI practice test comprises of 4 modules in</p>
      <div className="mt-2 flex space-x-2">
        <span className="rounded bg-indigo-500 px-2 py-1 text-xs text-white">{year}</span>
        <span className="bg-base-100 rounded px-2 py-1 text-xs text-white">{duration}</span>
      </div>
    </button>
  );
};

export default MockTest;
