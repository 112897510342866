import { useEffect, useState } from 'react';
import { STAGES } from '@app/constants/stages';
import { InfoModal } from '@app/components/common/Modal/Modal';
import { Button } from '@app/components/common/buttons/Button/Button';
import { AudioPlayer } from '@app/components/exam/AudioPlayer';
import * as S from './ListeningHighlightIncorectWord.styles';
import useCountdownTimer from '@app/hooks/useCountdownTimer';

interface Props {
  audioQuestion: string;
  prepTime: number;
  showAnswer: boolean;
  description: string;
  correctAnswersString: string;
  questionNumber: string;
}

export const ListeningHighlightIncorectWord: React.FC<Props> = ({
  audioQuestion,
  prepTime,
  showAnswer,
  description,
  correctAnswersString,
  questionNumber,
}) => {
  const [selectedWords, setSelectedWords] = useState<{ word: string; index: number }[]>([]);

  const [stage, setStage] = useState<string | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [correctAnswers, setCorrectAnswers] = useState<string[]>([]);

  const { seconds, isRunning, start, pause, reset } = useCountdownTimer({
    initialSeconds: prepTime,
    onComplete: () => {
      setStage(STAGES.PLAYING);
      setIsPlaying(true);
    },
  });

  useEffect(() => {
    retry();

    InfoModal({
      title: 'Additional permissions',
      content: 'Click the OK button to enable record audio.',
      onOk: () => {
        start();
        setStage(STAGES.START);
      },
    });
  }, [questionNumber]);

  const retry = () => {
    setSelectedWords([]);
    setIsPlaying(false);
    setStage(null);
    reset();

    if (correctAnswersString) {
      const resultArray = correctAnswersString.split(',').map((item) => item.trim());
      setCorrectAnswers(resultArray);
    }
  };

  const handleEnded = () => {
    setIsPlaying(false);
    if (stage === STAGES.PLAYING) setStage(STAGES.COMPLETE);
  };

  const handleWordClick = (word: string, index: number) => {
    const isAlreadySelected = selectedWords.some((selected) => selected.index === index);

    if (isAlreadySelected) {
      setSelectedWords((prev) => prev.filter((selected) => selected.index !== index));
    } else {
      setSelectedWords((prev) => [...prev, { word, index }]);
    }
    // setShowAnswer(false);
  };

  const words = description.split(' ').map((word, index) => {
    const selectedWord = selectedWords.find((selected) => selected.index === index);
    const isSelected = selectedWord !== undefined;
    const isCorrect = correctAnswers.includes(word);

    let color = 'black';
    if (showAnswer) {
      if (isCorrect) {
        color = 'green'; // Correct answers are green whether selected or not
      } else if (isSelected && !isCorrect) {
        color = 'red'; // Selected incorrect answers are red
      }
    } else if (isSelected) {
      color = 'blue'; // Selected words are blue before showing answer
    }

    return (
      <span
        key={index}
        onClick={() => handleWordClick(word, index)}
        style={{
          cursor: 'pointer',
          color: color,
          fontWeight: isSelected || (showAnswer && isCorrect) ? 'bold' : 'normal',
        }}
      >
        {word}{' '}
      </span>
    );
  });

  return (
    <>
      <S.WrapperAudio>
        {stage === STAGES.START && (
          <p>
            Beginning in <S.TextRed>{seconds}</S.TextRed> seconds
          </p>
        )}
        {stage === STAGES.PLAYING && <p>Status: playing...</p>}
        {stage === STAGES.COMPLETE && <p>Status: Completed</p>}
        <AudioPlayer audioQuestion={audioQuestion} onEnded={handleEnded} startPlaying={isPlaying} />
      </S.WrapperAudio>
      <div className="mt-5">{words}</div>
    </>
  );
};
