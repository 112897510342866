import { Typography } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
`;

export const Title = styled(Typography.Title)`
  color: #333 !important;
  text-align: center;
`;

export const Box = styled.div`
  margin: 8px;
`;

interface DroppableContainerProps {
  isDraggingOver: boolean;
}

export const DroppableContainer = styled.div<DroppableContainerProps>`
  padding: 4px;
  min-height: 500px;
  background-color: ${(props) => (props.isDraggingOver ? 'lightblue' : 'lightgrey')};
  transition: background-color 0.3s ease;
`;

export const DraggableItem = styled.div<{ isDragging: boolean }>`
  user-select: none;
  padding: 16px;
  margin: 0 0 8px 0;
  min-height: 50px;
  background-color: ${(props) => (props.isDragging ? '#263B4A' : '#456C86')};
  color: white;
  transition: background-color 0.3s ease;
`;
