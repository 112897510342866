import * as S from './AnswerSummarizeSpokenText.styles';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Table } from 'components/common/Table/Table';

export const AnswerSummarizeSpokenText = () => {
  const columns = [
    {
      title: 'CONENT',
      dataIndex: 'content',
      key: 'content',
    },
    {
      title: 'FORM',
      dataIndex: 'form',
      key: 'form',
    },
    {
      title: 'STRUCTURE',
      dataIndex: 'structure',
      key: 'structure',
    },
    {
      title: 'GRAMMAR',
      dataIndex: 'grammar',
      key: 'grammar',
    },
    {
      title: 'LINGUISTIC',
      dataIndex: 'linguistic',
      key: 'linguistic',
    },
    {
      title: 'VOCABULARY',
      dataIndex: 'vocabulary',
      key: 'vocabulary',
    },
    {
      title: 'SPELLING',
      dataIndex: 'spelling',
      key: 'spelling',
    },
    {
      title: 'TOTAL',
      dataIndex: 'total',
      key: 'total',
    },
  ];
  const data = [
    {
      key: 1,
      content: 1,
      form: 0,
      structure: 0,
      grammar: 0,
      linguistic: 0,
      vocabulary: 0,
      spelling: 0,
      total: 0 / 15,
    },
  ];
  return (
    <S.CardAnswer>
      <S.CardAnswerHead>
        <S.Title level={5}>Scoring Results</S.Title>
      </S.CardAnswerHead>

      <S.CardAnswerBody>
        <S.CardAnswerBox>
          <S.Grading>Transcript</S.Grading>
          <S.Content>
            What we know is that the impact of the pandemic would be catastrophic if it is similar to what we had in
            1918. In the United States, there has been unprecedented amount of preparation so far. It’s ..um, affects
            every aspect of public health. We have efforts for treatment, efforts for better prevention, clinical
            management, key communications, the domestic and international responses, and also efforts to try to prevent
            transmission within community. The federal government has had tremendous amount of resources that they have
            put into development of the new antiviral drugs, antiviral drugs stock piles; development of new vaccines
            and manufacturing facilities for vaccines. So, there’s quite a lot that’s happening in the United States.
            However, developing countries do not have the level of resources found in more developed countries. That’s
            the real challenge.
          </S.Content>
        </S.CardAnswerBox>
        <S.AnswerTable>
          <Table columns={columns} scroll={{ x: 800 }} dataSource={data} pagination={false} />

          <S.TextRed>*Word count contains less than 40 words or more than 100 words. No further scoring.</S.TextRed>
        </S.AnswerTable>
      </S.CardAnswerBody>
      <S.CardAnswerFooter>
        {/* <Button className="mt-4" type="primary">
                    Try now
                </Button> */}
      </S.CardAnswerFooter>
    </S.CardAnswer>
  );
};
