import { useEffect, useState } from 'react';

interface UseCountdownTimerOptions {
  initialSeconds: number;
  onComplete?: () => void;
}

const useCountdownTimer = ({ initialSeconds, onComplete }: UseCountdownTimerOptions) => {
  const [seconds, setSeconds] = useState<number | null>(initialSeconds);
  const [isRunning, setIsRunning] = useState(false);
  const [hasCompleted, setHasCompleted] = useState(false);

  useEffect(() => {
    if (seconds !== null && seconds > 0 && isRunning) {
      const interval = setInterval(() => {
        setSeconds((prevSeconds) => (prevSeconds !== null ? prevSeconds - 1 : 0));
      }, 1000);

      return () => clearInterval(interval);
    } else if (seconds === 0 && !hasCompleted) {
      setIsRunning(false);
      setHasCompleted(true);
      if (onComplete) onComplete();
    }
  }, [seconds, isRunning, onComplete, hasCompleted]);

  const start = () => {
    setIsRunning(true);
    setHasCompleted(false);
  };
  const pause = () => setIsRunning(false);
  const reset = (newSeconds: number = initialSeconds) => {
    setIsRunning(false);
    setSeconds(newSeconds);
    setHasCompleted(false);
  };

  return { seconds, isRunning, start, pause, reset };
};

export default useCountdownTimer;
