import styled from 'styled-components';

export const ContentQuestion = styled.div`
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  font-style: normal;
  text-align: justify;
  margin-bottom: 24px;
  padding: 12.8px;
  color: #3b3d44;
`;
