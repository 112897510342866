import React, { useEffect, useMemo, useState } from 'react';
import Overlay from '../../../../common/Overlay';
import { useLocation } from 'react-router-dom';
import { useResponsive } from 'hooks/useResponsive';
import * as S from './MainSider.styles';
import { SiderLogo } from '../SiderLogo';
import SiderMenu from '../SiderMenu/SiderMenu';

interface MainSiderProps {
  isCollapsed: boolean;
  setCollapsed: (isCollapsed: boolean) => void;
}

const MainSider: React.FC<MainSiderProps> = ({ isCollapsed, setCollapsed, ...props }) => {
  const { isDesktop, mobileOnly, tabletOnly } = useResponsive();
  const location = useLocation();
  const [isPageExam, setIsPageExam] = useState(false);

  const isCollapsible = useMemo(() => mobileOnly && tabletOnly, [mobileOnly, tabletOnly]);

  const toggleSider = () => setCollapsed(!isCollapsed);

  useEffect(() => {
    const shouldCollapse = isDesktop && ['/exam'].includes(location.pathname);
    setIsPageExam(shouldCollapse);
  }, [location.pathname, isDesktop, setCollapsed]);

  return (
    <>
      <S.Sider
        trigger={null}
        collapsed={(!isDesktop || isPageExam) && isCollapsed}
        collapsedWidth={tabletOnly || isPageExam ? 80 : 0}
        collapsible={isCollapsible}
        width={260}
        {...props}
      >
        <SiderLogo isSiderCollapsed={isCollapsed} toggleSider={toggleSider} />
        <S.SiderContent>
          <SiderMenu setCollapsed={setCollapsed} />
        </S.SiderContent>
      </S.Sider>
      {mobileOnly && <Overlay onClick={toggleSider} show={!isCollapsed} />}
    </>
  );
};

export default MainSider;
