/* eslint-disable @typescript-eslint/no-empty-interface */
import { Table } from 'components/common/Table/Table';
import * as S from './Answer.styles';

interface AnswerProps {}
export const Answer: React.FC<AnswerProps> = () => {
  const columns = [
    {
      title: 'CONENT',
      dataIndex: 'content',
      key: 'content',
    },
    {
      title: 'FORM',
      dataIndex: 'form',
      key: 'form',
    },
    {
      title: 'STRUCTURE',
      dataIndex: 'structure',
      key: 'structure',
    },
    {
      title: 'GRAMMAR',
      dataIndex: 'grammar',
      key: 'grammar',
    },
    {
      title: 'LINGUISTIC',
      dataIndex: 'linguistic',
      key: 'linguistic',
    },
    {
      title: 'VOCABULARY',
      dataIndex: 'vocabulary',
      key: 'vocabulary',
    },
    {
      title: 'SPELLING',
      dataIndex: 'spelling',
      key: 'spelling',
    },
    {
      title: 'TOTAL',
      dataIndex: 'total',
      key: 'total',
    },
  ];
  const data = [
    {
      key: 1,
      content: 1,
      form: 0,
      structure: 0,
      grammar: 0,
      linguistic: 0,
      vocabulary: 0,
      spelling: 0,
      total: 0 / 15,
    },
  ];
  return (
    <S.CardAnswer>
      <S.CardAnswerHead>
        <S.Title level={5}>Scoring Results</S.Title>
      </S.CardAnswerHead>
      <S.CardAnswerBody>
        <Table columns={columns} scroll={{ x: 800 }} dataSource={data} pagination={false} />
      </S.CardAnswerBody>
    </S.CardAnswer>
  );
};
