import styled from 'styled-components';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';

export const ContentQuestion = styled.div`
  margin: 24px 0;
`;

export const CheckboxStyle = styled(Checkbox)`
  display: flex;
  gap: 8px;
  color: #333;
`;
