import { Typography } from 'antd';
import styled from 'styled-components';

export const CardAnswer = styled.div`
  border: 1px solid #000;
  border-radius: 4px;
  margin-bottom: 5rem;
`;

export const CardAnswerHead = styled.div`
  padding: 22px 24px;
`;

export const Title = styled(Typography.Title)`
  font-weight: 700;
  line-height: 1.1;
  font-size: 1.625rem;
  margin: 0 0 2rem;
  color: #333 !important;
`;

export const CardAnswerBody = styled.div``;

export const CardAnswerBox = styled.div`
  display: grid;
  grid-template-columns: 120px 1fr;
  font-size: 18px;
  gap: 1px;
  padding: 0 24px 24px;
`;

export const AnswerTable = styled.div`
  padding: 0 24px 24px;
`;

export const Grading = styled.p`
  outline: gray solid 1px;
  font-weight: 600;
  padding: 10px;
  margin: 0px;
  letter-spacing: 1px;
  font-size: 14px;
  color: #333;
`;

export const Content = styled.div`
  outline: gray solid 1px;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
`;

export const LevelList = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 20px;
`;

export const Average = styled.div`
  width: 15px;
  height: 15px;
  background: #facd0a;
  border-radius: 50%;
`;

export const Missing = styled.div`
  width: 15px;
  height: 15px;
  background: #ff0000;
  border-radius: 50%;
`;

export const Good = styled.div`
  width: 15px;
  height: 15px;
  background: green;
  border-radius: 50%;
`;

export const Formula = styled.div`
  outline: gray solid 1px;
  padding: 25px;
  grid-column: 1 / 3;
  margin: 0px;
`;

export const Recognition = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 100%;
`;

export const Block = styled.div`
  background: var(--ant-primary-color);
  padding: 12px 5px;
  max-width: 170px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;

  &:hover {
    -webkit-transform: translate(-0.75rem, -0.25rem);
    transform: translate(-0.75rem, -0.25rem);
    box-shadow: 8px 8px 0 rgba(0, 0, 0, 0.5);
  }
`;

export const Plus = styled.div`
  padding: 0 15px;
  display: inline-block;
  font-size: 25px;
  font-weight: 600;
`;

export const CardAnswerFooter = styled.div`
  padding: 0 24px 24px;
`;

export const Audio = styled.div`
  padding: 24px;
  margin: 0 24px 24px;
  border: 1px solid rgb(0, 0, 0);
`;

export const TextRed = styled.div`
  color: red;
`;
