import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 8fr 1fr;
  gap: 16px;
  align-items: center;
`;

export const QuestionNum = styled.span`
  background: #9fa2ae;
  padding: 0.5rem;
  border-radius: 3px;
  color: #fff;
`;

export const Tag = styled.div<{ difficulty: string }>`
  padding: 2px 8px;
  border-radius: 90px;
  color: #fff;
  font-size: 14px;
  text-transform: capitalize;

  background-color: ${({ difficulty }) =>
    difficulty === 'easy'
      ? 'rgb(39, 194, 129)'
      : difficulty === 'medium'
      ? 'rgb(33, 81, 236)'
      : difficulty === 'hard'
      ? 'rgb(209, 54, 54)'
      : 'gray'};
`;
