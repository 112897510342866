import { FONT_SIZE, media } from '@app/styles/themes/constants';
import { Typography } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.ul`
  max-width: 831px;
  margin: auto;
  padding: 1rem;
  background: #f9f9f9;
`;

export const Title = styled(Typography.Title)`
  &.ant-typography {
    position: relative;
    margin: 15px 0;
    color: var(--text-dark-color);
    font-size: ${FONT_SIZE.md};
    padding-left: 10px;
    text-transform: uppercase;

    &:before {
      content: '';
      width: 32px;
      height: 4px;
      padding-right: 8px;
      background-color: #fecc65;
      position: absolute;
      -webkit-transform: rotate(90deg) translate(20px, 25px);
      transform: rotate(90deg) translate(20px, 25px);
      border-radius: 12px;
    }

    @media only screen and ${media.xl} {
      font-size: ${FONT_SIZE.xxxxl};
    }
  }
`;

export const StyledList = styled.ul`
  list-style-type: disc;
  padding-left: 30px;

  li {
    font-size: 16px;
    font-weight: 400;
    line-height: 35px;
  }
`;
