import { useEffect, useState } from 'react';
import { STAGES } from '@app/constants/stages';
import { InfoModal } from '@app/components/common/Modal/Modal';
import { AudioPlayer } from '@app/components/exam/AudioPlayer';
import { TextArea } from '@app/components/common/inputs/Input/Input';
import { Button } from '@app/components/common/buttons/Button/Button';
import * as S from './ListeningSummarizeSpokenText.styles';
import { AnswerSummarizeSpokenText } from '../../Answer/AnswerSummarizeSpokenText';
import useCountdownTimer from '@app/hooks/useCountdownTimer';

interface Props {
  prepTime: number;
  audioQuestion: string;
  showAnswer: boolean;
  questionNumber: string;
}

export const ListeningSummarizeSpokenText: React.FC<Props> = ({
  prepTime,
  audioQuestion,
  showAnswer,
  questionNumber,
}) => {
  const [stage, setStage] = useState<string | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [answerContent, setAnswerContent] = useState<string>('');
  const wordCount = answerContent.trim().split(/\s+/).filter(Boolean).length;

  const { seconds, isRunning, start, pause, reset } = useCountdownTimer({
    initialSeconds: prepTime,
    onComplete: () => {
      setStage(STAGES.PLAYING);
      setIsPlaying(true);
    },
  });

  useEffect(() => {
    retry();

    InfoModal({
      title: 'Additional permissions',
      content: 'Click the OK button to enable record audio.',
      onOk: () => {
        start();
        setStage(STAGES.START);
      },
    });
  }, [questionNumber]);

  const retry = () => {
    setAnswerContent('');
    setStage(null);
    setIsPlaying(false);
    reset();
  };

  const handleEnded = () => {
    setIsPlaying(false);
    if (stage === STAGES.PLAYING) setStage(STAGES.COMPLETE);
  };

  return (
    <>
      <S.Wrapper>
        {stage === STAGES.START && (
          <p>
            Beginning in <S.TextRed>{seconds}</S.TextRed> seconds
          </p>
        )}
        {stage === STAGES.PLAYING && <p>Status: playing...</p>}
        {stage === STAGES.COMPLETE && <p>Status: Completed</p>}
        <AudioPlayer audioQuestion={audioQuestion} onEnded={handleEnded} startPlaying={isPlaying} />
      </S.Wrapper>

      <TextArea
        rows={10}
        placeholder="Please write your answer here"
        value={answerContent}
        onChange={(e) => setAnswerContent(e.target.value)}
        className="mt-5"
      />

      <div>
        Word count: <span style={{ color: wordCount > 70 ? 'red' : 'black' }}>{wordCount}</span>
        /70
      </div>

      <div className="mt-5">{showAnswer && <AnswerSummarizeSpokenText />}</div>
    </>
  );
};
