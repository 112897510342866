/* eslint-disable react/no-unescaped-entities */
import { Waveform } from '../../Waveform';
import * as S from './Answer.styles';
import { Button } from '@app/components/common/buttons/Button/Button';

interface AnswerProps {
  audioURL: string;
  gradingContent: string;
  showNote: boolean;
  transcript?: boolean;
}

export const Answer: React.FC<AnswerProps> = ({ audioURL, gradingContent, showNote, transcript = false }) => {
  return (
    <S.CardAnswer>
      <S.CardAnswerHead>
        <S.Title level={5}>Answer</S.Title>
      </S.CardAnswerHead>

      <S.Audio>
        <p className="text-sm font-semibold mb-4">Student's record</p>
        {audioURL && <Waveform url={audioURL} />}
      </S.Audio>

      <S.CardAnswerBody>
        {transcript ? (
          <>
            <S.Grading>Transcript</S.Grading>
            <S.Content></S.Content>
          </>
        ) : (
          <>
            <S.Grading>Grading</S.Grading>
            <S.Content>
              {gradingContent}
              <S.LevelList>
                <div className="flex items-center gap-2">
                  <S.Good></S.Good>
                  <span className="text-black">Good</span>
                </div>
                <div className="flex items-center gap-2">
                  <S.Average></S.Average>
                  <span className="text-black">Average</span>
                </div>
                <div className="flex items-center gap-2">
                  <S.Missing></S.Missing>
                  <span className="text-black">Missing</span>
                </div>
              </S.LevelList>
            </S.Content>
          </>
        )}

        <S.Formula>
          <S.Recognition>
            <S.Block>
              <p>Content</p>
              <p>0/5</p>
            </S.Block>
            <S.Plus>+</S.Plus>
            <S.Block>
              <p>Pronunciation</p>
              <p>0/5</p>
            </S.Block>
            <S.Plus>+</S.Plus>
            <S.Block>
              <p>Fluency</p>
              <p>0/5</p>
            </S.Block>
            <S.Plus>=</S.Plus>

            <S.Block>
              <p>Total</p>
              <p>0.0/15</p>
            </S.Block>
          </S.Recognition>
        </S.Formula>
      </S.CardAnswerBody>
      <S.CardAnswerFooter>
        {showNote && (
          <>
            <p className="text-xl font-bold">Notes</p>
            <p className="text-base font-normal text-gray-500">
              Improve your Read Aloud skills with the PTE Magic - Read Aloud Speaking Advance Feature
            </p>
          </>
        )}

        {/* <Button className="mt-4" type="primary">
                    Try now
                </Button> */}
      </S.CardAnswerFooter>
    </S.CardAnswer>
  );
};
