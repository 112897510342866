import styled from 'styled-components';
import { RadioGroup } from '@app/components/common/Radio/Radio';

export const ContentQuestion = styled.div`
  margin: 24px 0;
`;

export const RadioGroupStyle = styled(RadioGroup)`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .ant-radio-wrapper {
    color: #333;
  }
  .ant-radio-inner {
    background: #fff;
  }
`;
