import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BoxSection } from './common/BoxSection';
import * as S from './PTESection.styles';
import { getQuestionsByType, IQuestionByType } from '@app/api/academic.api';
import { LISTENING, READING, SPEAKING, WRITING } from '@app/constants/questions';

export interface IPTESection {
  type: string;
  title: string;
  desc: string;
  desc1: string;
}

const data: IPTESection[] = [
  {
    type: 'speaking',
    title: 'Speaking',
    desc: 'The first part of the test will evaluate your Speaking and Writing skills ; this section is approximately of 75-95 minutes. It evaluates your English Language proficiency in an academic environment',
    desc1:
      "<p>There are 5 tasks in the section:</p><ul style='list-style-type: disc; padding-left: 30px;'><li>Read aloud</li><li>Repeat sentence</li><li>Describe image</li><li>Re-tell lecture</li><li>Answer short question</li></ul>",
  },
  {
    type: 'writing',
    title: 'Writing',
    desc: 'The Writing is the second section of the Speaking module. The writing test assesses your writing skills in terms of how good you are in grammar, formatting sentences, and sticking to the topic given. You will be judged on the quality of your writing.',
    desc1:
      "<p>There are 2 tasks in the section:</p><ul style='list-style-type: disc; padding-left: 30px;'><li>Summarize written text</li><li>Write essay</li></ul>",
  },
  {
    type: 'reading',
    title: 'Reading',
    desc: 'PTE Reading is the second section of the test and will take approximately 30-40 minutes. The reading materials for this section are all authentic texts about a variety of academic subjects, including humanities, natural sciences and social sciences.',
    desc1:
      "<p>There are 2 tasks in the section:</p><ul style='list-style-type: disc; padding-left: 30px;'><li>Summarize written text</li><li>Write essay</li></ul>",
  },
  {
    type: 'listening',
    title: 'Listening',
    desc: 'PTE Listening is the final section of the test and will take approximately 45-60 minutes, depending on the combination of tasks you may be presented with.',
    desc1:
      "<p>There are 2 tasks in the section:</p><ul style='list-style-type: disc; padding-left: 30px;'><li>Summarize written text</li><li>Write essay</li></ul>",
  },
];

const questionMap: Record<string, string[]> = {
  SPEAKING,
  WRITING,
  READING,
  LISTENING,
};

export const PTESection: React.FC = () => {
  const { section } = useParams();
  const [questions, setQuestions] = useState<IQuestionByType[]>([]);

  useEffect(() => {
    const fetchQuestions = async () => {
      const upperCaseSection = section?.toUpperCase();
      if (upperCaseSection && questionMap[upperCaseSection]) {
        const res = await getQuestionsByType(upperCaseSection, questionMap[upperCaseSection]);
        setQuestions(res);
      }
    };

    fetchQuestions();
  }, [section]);

  const dataPage = useMemo(() => data.find((item) => item.type === section), [data, section]);

  return (
    <S.Wrapper>
      <div className="flex flex-1 flex-col bg-white gap-6 px-6 py-4">
        <div>
          <div className="grid grid-cols-2 gap-4">
            <div className="col-span-2">
              <div className="shadow-xs flex flex-col rounded-lg">
                <S.Title level={3}>{section}</S.Title>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 text-black">
            <div>
              <p className="text-base font-normal">{dataPage?.desc}</p>
            </div>
            <div>
              <div dangerouslySetInnerHTML={{ __html: dataPage?.desc1 || '' }} />{' '}
            </div>
            {/* <div>{questions.length > 0 && <BoxSection data={questions[0]} />}</div> */}
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 text-black">
          {questions.length > 0 &&
            questions.map((item, index) => (
              <BoxSection key={`section-${item.type}`} data={item} section={section || ''} />
            ))}
        </div>
      </div>
    </S.Wrapper>
  );
};
