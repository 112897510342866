import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  border: 1px solid #000;
  border-radius: 10px;
  margin-bottom: 24px;
`;

export const ContenQuestion = styled.div`
  padding: 20px;
  line-height: 40px;
`;

export const SelectStyle = styled.select`
  margin: 5px;
  height: 32px;
  border-radius: 5px;
  min-width: 120px;
  border: 1px solid #d9d9d9;
  padding: 0 16px;
  background: #fff;
`;

export const CorrectAnswer = styled.span`
  color: green;
  margin-left: 5px;
  font-weight: 600;
`;
