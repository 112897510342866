import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;
export const Column = styled.div`
  flex: 1;
  padding: 16px;
`;

export const Box = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-style: dashed;
  border-width: 1px;
  border-radius: 0.5rem;
  padding: 1rem;
`;

export const TextRed = styled.span`
  color: red;
`;
