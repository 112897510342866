import styled from 'styled-components';

export const Box = styled.div`
  cursor: pointer;
  box-shadow: none;
  border: 1px solid #000;
  border-radius: 4px;
  width: 100%;
  transition: box-shadow 0.2s ease, -webkit-transform 0.2s ease;
  transition: box-shadow 0.2s ease, transform 0.2s ease;
  transition: box-shadow 0.2s ease, transform 0.2s ease, -webkit-transform 0.2s ease;

  &:hover {
    -webkit-transform: translate(-0.75rem, -0.25rem);
    transform: translate(-0.75rem, -0.25rem);
    box-shadow: 8px 8px 0;
  }
`;

export const BoxType = styled.div`
  height: 19px;
  background: #000;
  color: #fff;
  font-size: 12px;
  padding: 1px 3px;
  text-align: center;
  display: inline-block;
`;

export const BoxTitle = styled.p`
  font-size: 14px;
  padding: 10px 0;
  font-weight: 700;
  text-transform: uppercase;
`;

export const BoxTag = styled.span`
  background-color: #f1acab;
  padding: 0.25rem 0.75rem;
  font-weight: 700;
`;
