import { useEffect, useState } from 'react';
import * as S from './ListeningMCQLMultipleAnswer.styles';
import { Button } from '@app/components/common/buttons/Button/Button';
import { CloseIcon } from '@app/components/common/icons/CloseIcon';
import { CheckSuccessIcon } from '@app/components/common/icons/CheckSuccessIcon';
import { STAGES } from '@app/constants/stages';
import { AudioPlayer } from '@app/components/exam/AudioPlayer';
import { InfoModal } from '@app/components/common/Modal/Modal';
import useCountdownTimer from '@app/hooks/useCountdownTimer';

interface Props {
  audioQuestion: string;
  prepTime: number;
  showAnswer: boolean;
  initialChoicesString: string;
  questionNumber: string;
  correctAnswersString: string;
}

export const ListeningMCQLMultipleAnswer: React.FC<Props> = ({
  audioQuestion,
  prepTime,
  showAnswer,
  initialChoicesString,
  questionNumber,
  correctAnswersString,
}) => {
  const [stage, setStage] = useState<string | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [choices, setChoices] = useState<{ label: string; text: string }[]>([]);
  const [correctAnswers, setCorrectAnswers] = useState<string[]>([]);
  const [selectedAnswers, setSelectedAnswers] = useState<string[]>([]);

  const { seconds, isRunning, start, pause, reset } = useCountdownTimer({
    initialSeconds: prepTime,
    onComplete: () => {
      setStage(STAGES.PLAYING);
      setIsPlaying(true);
    },
  });

  useEffect(() => {
    retry();
    InfoModal({
      title: 'Additional permissions',
      content: 'Click the OK button to enable record audio.',
      onOk: () => {
        start();
        setStage(STAGES.START);
      },
    });
  }, [questionNumber]);

  const retry = () => {
    setSelectedAnswers([]);
    setStage(null);
    setIsPlaying(false);
    reset();

    if (initialChoicesString) {
      const parsedChoices = JSON.parse(initialChoicesString);
      setChoices(parsedChoices);
    }
    if (correctAnswersString) {
      const resultArray = correctAnswersString.split(',').map((item) => item.trim());
      setCorrectAnswers(resultArray);
    }
  };

  const handleEnded = () => {
    setIsPlaying(false);
    if (stage === STAGES.PLAYING) setStage(STAGES.COMPLETE);
  };

  const handleCheckboxChange = (label: string) => {
    setSelectedAnswers((prev) => (prev.includes(label) ? prev.filter((item) => item !== label) : [...prev, label]));
  };

  return (
    <>
      <S.Wrapper>
        {stage === STAGES.START && (
          <p>
            Beginning in <S.TextRed>{seconds}</S.TextRed> seconds
          </p>
        )}
        {stage === STAGES.PLAYING && <p>Status: playing...</p>}
        {stage === STAGES.COMPLETE && <p>Status: Completed</p>}
        <AudioPlayer audioQuestion={audioQuestion} onEnded={handleEnded} startPlaying={isPlaying} />
      </S.Wrapper>

      <S.ContentQuestion>Which of the following best summarises the main message of this text?</S.ContentQuestion>

      {choices.map((item) => (
        <div key={item.label} className="flex gap-2 items-center mb-4">
          {showAnswer && (
            <span
              style={{
                width: '1em',
                fontSize: '1rem',
                color: correctAnswers.includes(item.label) ? 'green' : 'red',
              }}
            >
              {correctAnswers.includes(item.label) ? <CheckSuccessIcon /> : <CloseIcon />}
            </span>
          )}
          <S.CheckboxStyle
            checked={selectedAnswers.includes(item.label)}
            onChange={() => handleCheckboxChange(item.label)}
          >
            {item.text}
          </S.CheckboxStyle>
        </div>
      ))}
    </>
  );
};
