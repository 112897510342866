import { getDetailExam, getExams, IExam, IExamList } from '@app/api/academic.api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Thunk để lấy danh sách câu hỏi (Phân trang)
export const fetchExamList = createAsyncThunk(
  'exam/fetchExamList',
  async ({ page = 1, type }: { page: number; type: string }) => {
    const response = await getExams(page, 20, type);
    return response;
  },
);

// Thunk để lấy chi tiết câu hỏi
export const fetchExamDetail = createAsyncThunk('exam/fetchExamDetail', async (id: string) => {
  const response = await getDetailExam(id);
  return response;
});

interface ExamState {
  listExam: IExam[];
  currentQuestion: IExam | null;
  page: number;
  totalPages: number;
  loading: boolean;
  selectedOptions: Record<string, string>;
}

const initialState: ExamState = {
  listExam: [],
  currentQuestion: null,
  page: 1,
  totalPages: 1,
  loading: false,
  selectedOptions: {},
};

export const examSlice = createSlice({
  name: 'exam',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    clearCurrentQuestion: (state) => {
      state.currentQuestion = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExamList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchExamList.fulfilled, (state, action) => {
        state.loading = false;
        state.listExam = action.payload.data;
        state.totalPages = action.payload.meta.itemCount;
        state.page = action.payload.meta.page;
      })
      .addCase(fetchExamDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchExamDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.currentQuestion = action.payload;
      });
  },
});

export const { setPage, clearCurrentQuestion } = examSlice.actions;
export default examSlice.reducer;
