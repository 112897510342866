import { useEffect, useState } from 'react';
import * as S from './ListeningMCQRSingleAnswer.styles';
import { Radio } from '@app/components/common/Radio/Radio';
import { CloseIcon } from '@app/components/common/icons/CloseIcon';
import { CheckSuccessIcon } from '@app/components/common/icons/CheckSuccessIcon';
import { STAGES } from '@app/constants/stages';
import { AudioPlayer } from '@app/components/exam/AudioPlayer';
import { InfoModal } from '@app/components/common/Modal/Modal';
import useCountdownTimer from '@app/hooks/useCountdownTimer';

interface Props {
  audioQuestion: string;
  prepTime: number;
  showAnswer: boolean;
  questionNumber: string;
  initialChoicesString: string;
  correctAnswersString: string;
}
export const ListeningMCQRSingleAnswer: React.FC<Props> = ({
  audioQuestion,
  prepTime,
  showAnswer,
  questionNumber,
  initialChoicesString,
  correctAnswersString,
}) => {
  const [stage, setStage] = useState<string | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [choices, setChoices] = useState<{ label: string; text: string }[]>([]);
  const [selectedValue, setSelectedValue] = useState(null);

  const { seconds, isRunning, start, pause, reset } = useCountdownTimer({
    initialSeconds: prepTime,
    onComplete: () => {
      setStage(STAGES.PLAYING);
      setIsPlaying(true);
    },
  });

  useEffect(() => {
    retry();
    InfoModal({
      title: 'Additional permissions',
      content: 'Click the OK button to enable record audio.',
      onOk: () => {
        start();
        setStage(STAGES.START);
      },
    });
  }, [questionNumber]);

  const retry = () => {
    setSelectedValue(null);
    setStage(null);
    setIsPlaying(false);
    reset();

    if (initialChoicesString) {
      const parsedChoices = JSON.parse(initialChoicesString);
      setChoices(parsedChoices);
    }
  };

  const handleEnded = () => {
    setIsPlaying(false);
    if (stage === STAGES.PLAYING) setStage(STAGES.COMPLETE);
  };

  return (
    <>
      <S.Wrapper>
        {stage === STAGES.START && (
          <p>
            Beginning in <S.TextRed>{seconds}</S.TextRed> seconds
          </p>
        )}
        {stage === STAGES.PLAYING && <p>Status: playing...</p>}
        {stage === STAGES.COMPLETE && <p>Status: Completed</p>}
        <AudioPlayer audioQuestion={audioQuestion} onEnded={handleEnded} startPlaying={isPlaying} />
      </S.Wrapper>

      <S.ContentQuestion>Which of the following best summarises the main message of this text?</S.ContentQuestion>

      <S.RadioGroupStyle
        value={selectedValue} // Liên kết state với RadioGroup
        onChange={(e) => setSelectedValue(e.target.value)}
      >
        {choices.map((item) => (
          <div key={item.label} className="flex gap-2 items-center">
            {showAnswer && (
              <span
                style={{
                  width: '1em',
                  fontSize: '1rem',
                  color: correctAnswersString === item.label ? 'green' : 'red',
                }}
              >
                {correctAnswersString === item.label ? <CheckSuccessIcon /> : <CloseIcon />}
              </span>
            )}
            <Radio value={item.label}>{item.text}</Radio>
          </div>
        ))}
      </S.RadioGroupStyle>
    </>
  );
};
