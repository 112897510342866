import React, { useEffect, useState } from 'react';
import * as S from './ReadingFIBRW.styles';
import { Button } from '@app/components/common/buttons/Button/Button';

interface Props {
  showAnswer: boolean;
  text: string;
  initialChoicesString: string;
  correctAnswersString: string;
}

export const ReadingFIBRW: React.FC<Props> = ({ showAnswer, text, initialChoicesString, correctAnswersString }) => {
  const numberOfBlanks = (text.match(/@Blank@/g) || []).length;
  const [filledBlanks, setFilledBlanks] = useState<string[]>(new Array(numberOfBlanks).fill(''));
  const [choices, setChoices] = useState<{ label: string; text: string }[]>([]);
  const [correctAnswers, setCorrectAnswers] = useState<string[]>([]);

  useEffect(() => {
    setFilledBlanks(new Array(numberOfBlanks).fill(''));
    if (initialChoicesString) {
      console.log(initialChoicesString);
      const parsedChoices = JSON.parse(initialChoicesString);
      setChoices(parsedChoices);
    }
  }, [initialChoicesString]);

  useEffect(() => {
    if (correctAnswersString) {
      const resultArray = correctAnswersString.split(',').map((item) => item.trim());
      setCorrectAnswers(resultArray);
    }
  }, [correctAnswersString]);

  const textWithBlanks = text.split(/@Blank@/).map((part, index) => {
    const filledBlank = filledBlanks[index] || '';
    const isBlank = index < filledBlanks.length;
    const isCorrect = filledBlank === correctAnswers[index];

    return (
      <React.Fragment key={`part-${index}`}>
        {part}
        {isBlank && (
          <S.SelectStyle
            name="select"
            style={{
              borderColor: showAnswer ? (isCorrect ? 'green' : 'red') : '#000',
            }}
            value={filledBlank}
            onChange={(e) => {
              const updatedFilledBlanks = [...filledBlanks];
              updatedFilledBlanks[index] = e.target.value;
              console.log(updatedFilledBlanks);
              setFilledBlanks(updatedFilledBlanks);
            }}
          >
            <option value=""></option>
            {choices.length > 0 &&
              choices[index].text.split('/').map((word) => (
                <option key={`${choices[index].label}-${word}`} value={word}>
                  {word}
                </option>
              ))}
          </S.SelectStyle>
        )}
        {showAnswer && !isCorrect && correctAnswers[index] && (
          <S.CorrectAnswer>({correctAnswers[index]})</S.CorrectAnswer>
        )}
      </React.Fragment>
    );
  });

  return (
    <>
      <S.Wrapper>
        <S.ContenQuestion>{textWithBlanks}</S.ContenQuestion>
      </S.Wrapper>
    </>
  );
};
