import * as S from './AudioPlayer.styles';
import ReactPlayer from 'react-player';
import { PauseIcon } from '@app/components/common/icons/PauseIcon';
import { VolumeIcon } from '@app/components/common/icons/VolumeIcon';
import { PlayingIcon } from '@app/components/common/icons/PlayingIcon';
import { Progress } from '@app/components/common/Progress/Progress';
import { useEffect, useRef, useState } from 'react';

interface PlayerProps {
  audioQuestion: string;
  onEnded: () => void;
  startPlaying: boolean;
}

const SPEED_VALUES = [0.5, 0.75, 1, 1.25, 1.5];
const primaryColor = 'var(--primary-color)';

export const AudioPlayer: React.FC<PlayerProps> = ({ audioQuestion, startPlaying, onEnded }) => {
  const playerRef = useRef<ReactPlayer>(null);
  const [speed, setSpeed] = useState<number>(1);
  const [volume, setVolume] = useState<number>(0.5);
  const [progress, setProgress] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  useEffect(() => {
    setIsPlaying(startPlaying);
    if (startPlaying) {
      setProgress(0);
      playerRef.current?.getInternalPlayer()?.play();
    }
  }, [startPlaying]);

  const togglePlay = () => {
    setIsPlaying((prev) => !prev);
  };

  const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVolume(parseFloat(e.target.value));
  };

  return (
    <>
      <S.Loaded>
        <a onClick={togglePlay}>{isPlaying ? <PauseIcon /> : <PlayingIcon />}</a>
        <Progress percent={progress} showInfo={false} strokeColor={primaryColor} />
      </S.Loaded>
      <div className="flex justify-end gap-2 text-sm">
        {SPEED_VALUES.map((sv) => {
          return (
            <button
              onClick={() => setSpeed(sv)}
              key={sv}
              style={{
                color: speed === sv ? 'black' : '#adb5bd',
              }}
              className="italic"
            >
              {sv}x
            </button>
          );
        })}
      </div>

      <div className="flex gap-4 items-center">
        <VolumeIcon />

        <div className="flex h-1 rounded-sm w-full">
          <input
            type="range"
            min={0}
            max={1}
            step={0.01}
            value={volume}
            onChange={handleVolumeChange}
            style={{
              width: '40%',
              height: '5px',
              appearance: 'none',
              background: `linear-gradient(to right, var(--primary-color) ${volume * 100}%, #484848 ${volume * 100}%)`,
              outline: 'none',
              borderRadius: '4px',
              transition: 'all 0.2s ease-in-out',
            }}
          />
        </div>
      </div>

      <ReactPlayer
        style={{ display: 'none' }}
        ref={playerRef}
        url={audioQuestion}
        playing={isPlaying}
        controls={false}
        playsinline={true}
        config={{
          file: {
            attributes: { preload: 'auto' },
            forceAudio: true,
          },
        }}
        playbackRate={speed}
        volume={volume}
        onProgress={({ played }) => setProgress(played * 100)}
        onEnded={onEnded}
        stopOnUnmount={true}
      />
    </>
  );
};
