import { useEffect, useState } from 'react';
import * as S from './ReadingMCQRMultipleAnswer.styles';
import { Button } from '@app/components/common/buttons/Button/Button';
import { CloseIcon } from '@app/components/common/icons/CloseIcon';
import { CheckSuccessIcon } from '@app/components/common/icons/CheckSuccessIcon';

interface Props {
  showAnswer: boolean;
  text: string;
  description: string;
  initialChoicesString: string;
  correctAnswersString: string;
}
export const ReadingMCQRMultipleAnswer: React.FC<Props> = ({
  showAnswer,
  text,
  description,
  initialChoicesString,
  correctAnswersString,
}) => {
  const [choices, setChoices] = useState<{ label: string; text: string }[]>([]);
  const [correctAnswers, setCorrectAnswers] = useState<string[]>([]);
  const [selectedAnswers, setSelectedAnswers] = useState<string[]>([]);

  useEffect(() => {
    setSelectedAnswers([]);
    if (initialChoicesString) {
      const parsedChoices = JSON.parse(initialChoicesString);
      setChoices(parsedChoices);
    }
  }, [initialChoicesString]);

  useEffect(() => {
    if (correctAnswersString) {
      const resultArray = correctAnswersString.split(',').map((item) => item.trim());
      setCorrectAnswers(resultArray);
    }
  }, [correctAnswersString]);

  const handleCheckboxChange = (label: string) => {
    setSelectedAnswers((prev) => (prev.includes(label) ? prev.filter((item) => item !== label) : [...prev, label]));
  };

  return (
    <>
      <div className="text-base font-medium">{text}</div>

      <S.ContentQuestion>{description}</S.ContentQuestion>

      {choices.map((item) => (
        <div key={item.label} className="flex gap-2 items-center mb-4">
          {showAnswer && (
            <span
              style={{
                width: '1em',
                fontSize: '1rem',
                color: correctAnswers.includes(item.label) ? 'green' : 'red',
              }}
            >
              {correctAnswers.includes(item.label) ? <CheckSuccessIcon /> : <CloseIcon />}
            </span>
          )}
          <S.CheckboxStyle
            checked={selectedAnswers.includes(item.label)}
            onChange={() => handleCheckboxChange(item.label)}
          >
            {item.text}
          </S.CheckboxStyle>
        </div>
      ))}
    </>
  );
};
