import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput';
import * as S from './DrawerListQuestion.styles';
import { useState } from 'react';
import { CloseIcon } from '@app/components/common/icons/CloseIcon';
import { CheckIcon } from '@app/components/common/icons/CheckIcon';
import { SearchIcon } from '@app/components/common/icons/SearchIcon';
import { Select, Option } from '@app/components/common/selects/Select/Select';
import { Pagination } from '@app/components/common/Pagination/Pagination';
import { IExam } from '@app/api/academic.api';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/store/store';
import { setPage } from '@app/store/slices/examSlice';
import { useNavigate } from 'react-router-dom';

interface DrawerListQuestionProps {
  open: boolean;
  toggle: () => void;
  type: string;
}

export const DrawerListQuestion: React.FC<DrawerListQuestionProps> = ({ open, type, toggle }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { listExam, currentQuestion, page, totalPages } = useSelector((state: RootState) => state.exam);
  const [activeTab, setActiveTab] = useState('all');

  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      dispatch(setPage(newPage));
    }
  };

  const handleExam = (examId: string) => {
    navigate(`/exam?type=${type}&id=${examId}&page=${page}`);
    toggle();
  };

  return (
    <>
      <S.Drawer open={open}>
        <S.BtnClose onClick={toggle}>
          <CloseIcon />
        </S.BtnClose>
        <S.DrawerContent>
          <S.ASQ>ASQ</S.ASQ>
          <p className="text-base font-bold my-2">Answer short question</p>
          <S.FormInput>
            <S.StyledInput type="text" placeholder="Search by question ID RA0001..." />
            <button>
              <SearchIcon />
            </button>
          </S.FormInput>

          <S.BoxTabs>
            <S.Tabs>
              <S.TabItem active={activeTab === 'all'} onClick={() => setActiveTab('all')}>
                ALL
              </S.TabItem>
              <S.TabItem active={activeTab === 'bookmarks'} onClick={() => setActiveTab('bookmarks')}>
                Bookmarks
              </S.TabItem>
            </S.Tabs>
            <div className="flex gap-4">
              <Select defaultValue="all" width={120} size={'small'}>
                <Option value="all">All levels</Option>
                <Option value="easy">Easy</Option>
              </Select>
              <Select defaultValue="all" width={120} size={'small'}>
                <Option value="all">All Status</Option>
                <Option value="done">Done</Option>
                <Option value="notDone">Not Done</Option>
              </Select>
            </div>
          </S.BoxTabs>

          <S.ListAnswer>
            {listExam.map((exam) => (
              <S.ItemAnswer
                key={exam.id}
                isCorrect={exam.id === currentQuestion?.id}
                onClick={() => handleExam(exam.id)}
              >
                <S.MainContent>
                  <span>{exam.orderId}</span>Answer short question
                </S.MainContent>
                <S.SideContent>
                  <div className="flex gap-2 items-center">
                    <S.Level difficulty={exam.difficulty}>{exam.difficulty}</S.Level>
                    {/* <S.Check>
                      <CheckIcon />
                    </S.Check> */}
                  </div>
                </S.SideContent>
              </S.ItemAnswer>
            ))}
          </S.ListAnswer>
          <Pagination
            className="text-center"
            showSizeChanger={false}
            defaultCurrent={page}
            total={totalPages}
            onChange={handlePageChange}
          />
        </S.DrawerContent>
      </S.Drawer>
      <S.Backdrop open={open} onClick={toggle}></S.Backdrop>
    </>
  );
};
