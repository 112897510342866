import styled from 'styled-components';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 28rem;
  border-radius: 0.5rem;
  padding: 1rem;
  border-style: dashed;
  border-width: 2px;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
export const TextRed = styled.span`
  color: red;
`;

export const ContentQuestion = styled.div`
  margin: 24px 0;
`;

export const CheckboxStyle = styled(Checkbox)`
  display: flex;
  gap: 8px;
  color: #333;
`;
