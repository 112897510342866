import styled from 'styled-components';

export const TextRed = styled.span`
  color: red;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 28rem;
  border-radius: 0.5rem;
  padding: 1rem;
  border-style: dashed;
  border-width: 2px;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Loaded = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
`;

export const Box = styled.div`
  width: 100%;
  max-width: 28rem;
  border-radius: 0.5rem;
  padding: 1rem;
  border-style: dashed;
  border-width: 2px;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1.25rem;
`;
