import * as S from './Completed.styles';
import { Progress } from '@app/components/common/Progress/Progress';

const primaryColor = 'var(--primary-color)';

export const Completed: React.FC = () => {
  return (
    <S.Box>
      <p>Completed</p>
      <Progress percent={100} showInfo={false} strokeColor={primaryColor} />
    </S.Box>
  );
};
