import styled from 'styled-components';

export const Box = styled.div`
  width: 100%;
  max-width: 28rem;
  border-radius: 0.5rem;
  padding: 1rem;
  border-style: dashed;
  border-width: 2px;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;
