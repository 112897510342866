export const QUESTIONS = {
  SPEAKING: {
    READ_ALOUD: 'SPEAKING_READ_ALOUD',
    DESCRIBE_IMAGE: 'SPEAKING_DESCRIBE_IMAGE',
    REPEAT_SENTENCE: 'SPEAKING_REPEAT_SENTENCE',
    RETELL_LECTURE: 'SPEAKING_RETELL_LECTURE',
    ANSWER_SHORT_QUESTION: 'SPEAKING_ANSWER_SHORT_QUESTION',
  },
  WRITING: {
    SUMMARIZE_WRITTEN_TEXT: 'WRITING_SUMMARIZE_WRITTEN_TEXT',
    WRITING_ESSAY: 'WRITING_ESSAY',
  },
  READING: {
    MCQ_R_SINGLE_ANSWER: 'READING_MCQ_R_SINGLE_ANSWER',
    MCQ_R_MULTIPLE_ANSWER: 'READING_MCQ_R_MULTIPLE_ANSWER',
    FIB_R: 'READING_FIB_R',
    FIB_R_W: 'READING_FIB_R_W',
    RE_ORDER_PARAGRAPH: 'READING_RE_ORDER_PARAGRAPH',
  },
  LISTENING: {
    DICTATION: 'LISTENING_DICTATION',
    HIGHLIGHT_INCORRECT_WORD: 'LISTENING_HIGHLIGHT_INCORRECT_WORD',
    MCQ_L_SINGLE_ANSWER: 'LISTENING_MCQ_L_SINGLE_ANSWER',
    MCQ_L_MULTIPLE_ANSWER: 'LISTENING_MCQ_L_MULTIPLE_ANSWER',
    FIB_L: 'LISTENING_FIB_L',
    SUMMARIZE_SPOKEN_TEXT: 'LISTENING_SUMMARIZE_SPOKEN_TEXT',
    HIGHLIGHT_CORRECT_SUMMARY: 'LISTENING_HIGHLIGHT_CORRECT_SUMMARY',
    SELECT_MISSING_WORD: 'LISTENING_SELECT_MISSING_WORD',
  },
};

export const SPEAKING = [
  'SPEAKING_READ_ALOUD',
  'SPEAKING_REPEAT_SENTENCE',
  'SPEAKING_DESCRIBE_IMAGE',
  'SPEAKING_RETELL_LECTURE',
  'SPEAKING_ANSWER_SHORT_QUESTION',
];

export const WRITING = ['WRITING_SUMMARIZE_WRITTEN_TEXT', 'WRITING_ESSAY'];

export const READING = [
  'READING_FIB_R',
  'READING_FIB_R_W',
  'READING_MCQ_R_SINGLE_ANSWER',
  'READING_RE_ORDER_PARAGRAPH',
  'READING_MCQ_R_MULTIPLE_ANSWER',
];

export const LISTENING = [
  'LISTENING_DICTATION',
  'LISTENING_FIB_L',
  'LISTENING_HIGHLIGHT_CORRECT_SUMMARY',
  'LISTENING_HIGHLIGHT_INCORRECT_WORD',
  'LISTENING_MCQ_L_MULTIPLE_ANSWER',
  'LISTENING_MCQ_L_SINGLE_ANSWER',
  'LISTENING_SELECT_MISSING_WORD',
  'LISTENING_SUMMARIZE_SPOKEN_TEXT',
];
