import { useEffect, useState } from 'react';
import { Progress } from '@app/components/common/Progress/Progress';
import { Button } from '@app/components/common/buttons/Button/Button';
import { RecordingIcon } from '@app/components/common/icons/RecordingIcon';
import * as S from './WaitingTime.styles';

interface WaitingTimeProps {
  onStart: () => void;
  waitingTime: number;
}

export const WaitingTime: React.FC<WaitingTimeProps> = ({ onStart, waitingTime }) => {
  const [seconds, setSeconds] = useState(waitingTime);

  useEffect(() => {
    if (seconds > 0) {
      const interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(interval);
    } else {
      onStart();
    }
  }, [seconds]);
  return (
    <S.Box>
      <p>
        Beginning in <S.TextRed>{seconds}</S.TextRed> seconds
      </p>
      <Progress percent={0} showInfo={false} />

      <Button shape="circle" icon={<RecordingIcon />} onClick={onStart} />
      <p>Start recording</p>
    </S.Box>
  );
};
